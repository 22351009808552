var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h100"},[_c('el-card',{staticClass:"box-card"},[_c('avue-crud',_vm._g(_vm._b({attrs:{"page":_vm.page},on:{"update:page":function($event){_vm.page=$event},"search-reset":_vm.searchChange,"search-change":_vm.searchChange},scopedSlots:_vm._u([{key:"bindStatusForm",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value?'解绑':'绑定')+" ")]}},{key:"tblCarTraceListForm",fn:function(ref){
var value = ref.value;
return [_vm._l((value),function(image){return [_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('el-image',{staticStyle:{"width":"200px","height":"200px"},attrs:{"src":_vm.imgUrl+image.url.replace('/root/file',''),"fit":"contain"}})],1),_c('el-col',{attrs:{"span":16}},[_c('div',[_vm._v("摄像头："+_vm._s(image.videoName))]),_c('div',[_vm._v("图片名称："+_vm._s(image.url.split('/')[image.url.split('/').length-1]))]),_c('div',[_vm._v("图片Id："+_vm._s(image.pictureId))]),_c('div',[_vm._v("车辆Id："+_vm._s(image.carId))]),_c('div',[_vm._v("globalId："+_vm._s(image.globalId))])])],1)]})]}},{key:"menu",fn:function(ref){
var type = ref.type;
var size = ref.size;
var row = ref.row;
var index = ref.index;
return [_c('el-button',{attrs:{"icon":"el-icon-view","size":size,"type":type},on:{"click":function($event){return _vm.view(row)}}},[_vm._v("查 看")])]}}]),model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},'avue-crud',_vm.bindVal,false),_vm.onEvent)),_c('el-dialog',{attrs:{"title":"操作详情","visible":_vm.dialogFormVisible},on:{"update:visible":function($event){_vm.dialogFormVisible=$event},"close":_vm.closeWindow}},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.form,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"操作动作:","prop":"bindStatus"}},[_vm._v(" "+_vm._s(_vm.form.bindStatus?'解绑':'绑定')+" ")]),_c('el-form-item',{attrs:{"label":"操作时间:","prop":"createTime"}},[_vm._v(" "+_vm._s(_vm.form.createTime)+" ")]),_c('el-form-item',{attrs:{"label":"相关数据:","prop":"tblCarTraceList"}},[_c('el-row',_vm._l((_vm.form.tblCarTraceOldList),function(image,imageindex){return _c('el-col',{key:image.pictureId,attrs:{"span":8}},[_c('el-card',{attrs:{"body-style":{ padding: '20px',lineHeight:'20px' }}},[_c('el-image',{staticStyle:{"width":"200px","height":"200px"},attrs:{"src":_vm.imgUrl+image.url.replace('/root/file',''),"preview-src-list":[_vm.imgUrl+image.url.replace('/root/file','')],"initial-index":imageindex,"fit":"contain"}}),_c('div',[_vm._v("摄像头："+_vm._s(image.videoName))]),_c('div',[_vm._v("图片名称："+_vm._s(image.url.split('/')[image.url.split('/').length-1]))]),_c('div',[_vm._v("图片Id："+_vm._s(image.pictureId))]),_c('div',[_vm._v("车辆Id："+_vm._s(image.carId))]),_c('div',[_vm._v("globalId："+_vm._s(image.globalId))])],1)],1)}),1)],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.closeWindow}},[_vm._v("关闭")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }