<template>
  <div class="h100">
    <el-card class="box-card">
      <avue-crud v-bind="bindVal"
                 v-on="onEvent"
                 :page.sync="page"
                 :search.sync="search"
                 @row-update="rowUpdate"
                 v-model="form">
      </avue-crud>
    </el-card>
  </div>
</template>

<script>
import { update } from '@/api/sys/permission.js'
export default window.$crudCommon({
  data(){
    return{
      updateDuplicateFlag:false
    }
  },
  methods: {
    rowUpdate(row, index, done){
      if(this.updateDuplicateFlag) return;
      this.updateDuplicateFlag = true;
      update(row).then(res=>{
        this.updateDuplicateFlag = false;
        this.$message.success('编辑成功')
        this.getList();
        done();
      })
    },
  },
}, {
  pageSize: 'size',
  pageNum: 'page',
  name: '/sys/permission',
  res: (data) => {
    return {
      total: data.total,
      data: data.records
    }
  }
})
</script>

<style lang="scss" scoped>
</style>