<template>
    <div class="h100">
      <el-card class="box-card">
        <avue-crud v-bind="bindVal"
                  v-on="onEvent"
                  :page.sync="page"
                  v-model="form">
                  <template slot="bindQty" slot-scope="scope">
                    <el-link type="primary" @click="detail(scope.row,0)">{{scope.row.bindQty}}</el-link>
                        
                  </template>
                  <template slot="unBindQty" slot-scope="scope">
                    <el-link type="primary" @click="detail(scope.row,1)">{{scope.row.unBindQty}}</el-link>
                  </template>
        </avue-crud>
        <el-dialog :title="detailTitle==0?'绑定详情':'解绑详情'" width="80%" :visible.sync="detailVisible" @close="closeDetailDialog">
            <avue-crud 
                  :option="detailOption"
                  :data="detailData"
                  @search-reset="searchChange"
                  @search-change="searchChange"
                  @current-change="currentChange"
                  @size-change="sizeChange"
                  :search.sync="detailSearch"
                  :page.sync="detailPage">
                  <template slot="bindStatusForm"
                    slot-scope="{value}">
                    {{value?'解绑':'绑定'}}
                  </template>
                  <template slot="tblCarTraceListForm"
                    slot-scope="{value}">
                    <!-- {{value}} -->
                      <template v-for="image in value">
                        <el-row>
                          <el-col :span="8">
                            <el-image
                            style="width: 200px; height: 200px"
                            :src="imgUrl+image.url.replace('/root/file','')"
                            fit="contain"></el-image>
                          </el-col>
                          <el-col :span="16">
                            <div>摄像头：{{image.videoName}}</div>
                            <div>图片名称：{{image.url.split('/')[image.url.split('/').length-1]}}</div>
                            <div>图片Id：{{image.pictureId}}</div>
                            <div>车辆Id：{{image.carId}}</div>
                            <div>globalId：{{image.globalId}}</div>
                          </el-col>
                        </el-row>
                      </template>
                    </template>
                    <template slot-scope="{type,size,row,index}" slot="menu">
                        <el-button icon="el-icon-view" :size="size" :type="type" @click="view(row)">查 看</el-button>
                      </template>
                </avue-crud>
          <div slot="footer" class="dialog-footer">
            <el-button @click="closeDetailDialog">关闭</el-button>
          </div>
        </el-dialog>
        <el-dialog title="操作详情" :visible.sync="dialogFormVisible" @close="closeWindow">
          <el-form :model="form" ref="ruleForm" label-width="80px">
            <el-form-item label="操作动作:" prop="bindStatus">
              {{form.bindStatus?'解绑':'绑定'}}
            </el-form-item>
            <el-form-item label="操作时间:" prop="createTime">
              {{form.createTime}}
            </el-form-item>
            <el-form-item label="相关数据:" prop="tblCarTraceList">
              <el-row>
                <el-col :span="8" v-for="(image,imageindex) in form.tblCarTraceList" :key="image.pictureId">
                  <el-card :body-style="{ padding: '20px',lineHeight:'20px'}">
                    <el-image
                      style="width: 200px; height: 200px"
                      :src="imgUrl+image.url.replace('/root/file','')"
                      :preview-src-list="[imgUrl+image.url.replace('/root/file','')]"
                      :initial-index="imageindex"
                      fit="contain"></el-image>
                      <div>摄像头：{{image.videoName}}</div>
                      <div>图片名称：{{image.url.split('/')[image.url.split('/').length-1]}}</div>
                      <div>图片Id：{{image.pictureId}}</div>
                      <div>车辆Id：{{image.carId}}</div>
                      <div>globalId：{{image.globalId}}</div>
                  </el-card>
                </el-col>
              </el-row>
                
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="closeWindow">关闭</el-button>
          </div>
        </el-dialog>
      </el-card>
    </div>
  </template>
  
  <script>
  import {list,detaillist} from '@/api/carTrace/countData'
  export default window.$crudCommon({
    data() {
      return {
        detailVisible:false,
        dialogFormVisible:false,
        dialogFormEdit:true,
        userOpenId:null,
        currentUser:null,
        dataRecord:[],
        dataRecordLoading:false,
        imgUrl:process.env.VUE_APP_BASE_API,
        formImgList:[],
        selectedIndex:0,
        detailTitle:0, 
        detailSearch:{
            actualCarIds:'',
        },
        detailPage:{},
        detailData:[],
        detailOption:{
                    translate: false,
                    searchLabelWidth: 100,
                    labelWidth: 110,
                    selection: true,
                    tip: false,
                    index: true,
                    align: 'center',
                    menu: true,
                    menuBtn:true,
                    headerAlign: 'center',
                    border: true,
                    stripe: true,
                    addBtn:false,
                    delBtn:false,
                    editBtn:false,
                    updateBtn:false,
                    viewBtn:false,
                    cancelBtn: false,
                    dialogCloseBtn:true,
                    page:true,
                    column: [{
                    label: "操作动作",
                    prop: "bindStatus",
                    slot: true,
                    html:true,
                    formatter:(val,value,label)=>{
                        if(value){
                            return `<span style="color:#999">解绑</span>`
                        }else{
                            return `<span style="color:#409EFF">绑定</span>`
                        }    
                    }
                    },
                    {
                    label: "操作时间",
                    prop: "createTime",
                    width:220,
                    slot: true,
                    },
                    {
                        label: "操作图片",
                        prop: "tblCarTraceList",
                        // hide:true,
                        // showColumn:false,
                        slot: true,
                        span:24,
                        html:true,
                        formatter:(val,value,label)=>{
                        let str = '' 
                        for(let i=0;i<value.length;i++){
                            str=str + value[i].videoName+':'+value[i].url.split('/')[value[i].url.split('/').length-1]+'<br/>'
                        }

                        return str
                    }
                    },
                    {
                    label: "车辆Id",
                    prop: "actualCarIds",
                    search: true,
                    hide:true,
                    showColumn:false
                    },
                    //
                ],
            }
      }
    },
    methods: {
     sizeChange (val) {
        this.detailPage.currentPage = 1
      this.detailPage.size = val
        let obj ={
            desc:'createTime',
            createUser:this.datailSearachData.userId,
            bindStatus:this.detailTitle,
            pageNum:this.detailPage.currentPage,
            pageSize:this.detailPage.size
        }
        this.getDetailList(obj)
    },
    searchChange (params, done) {
      if (done) done();
   
      this.detailPage.currentPage = 1
      let obj ={
            desc:'createTime',
            createUser:this.datailSearachData.userId,
            bindStatus:this.detailTitle,
            pageNum:this.detailPage.currentPage,
            pageSize:this.detailPage.size,
            actualCarIds:params.actualCarIds
        }
      this.getDetailList(obj)
    },
      currentChange (val) {
        this.detailPage.currentPage = val
        let obj ={
            desc:'createTime',
            createUser:this.datailSearachData.userId,
            bindStatus:this.detailTitle,
            pageNum:this.detailPage.currentPage,
            pageSize:this.detailPage.size
        }
        this.getDetailList(obj)
      },
      getDetailList(data){
        
        detaillist(data).then(res=>{
            this.detailData = res.data.records;
            this.detailPage.total=res.data.total;
            this.detailPage.size=res.data.size;
            this.detailPage.pages=res.data.pages;
            this.detailPage.currentPage=this.detailPage.currentPage;
        })
      },
      detail(data,type){
        this.detailTitle = type;
        this.datailSearachData = data;
        detaillist({createUser:this.datailSearachData.userId,bindStatus:type,desc:'createTime',}).then(res=>{
            this.detailData = res.data.records;
            this.detailPage.total=res.data.total;
            this.detailPage.size=res.data.size;
            this.detailPage.pages=res.data.pages;
            this.detailPage.currentPage=1;
            this.detailVisible = true
        })
      },
      viewImgList(index){
        this.selectedIndex = Number(index)
      },
      closeDetailDialog(){
        this.detailSearch.actualCarIds ='';
        this.detailVisible = false;
      },
      closeWindow(){
        this.dialogFormVisible = false;
        this.form = {
            name:'',
            organization:'',
            content:''
          }
          this.formImgList=[]
      },
      view(row){
        this.form = row;
        //图片预览操作
        this.form.tblCarTraceList.map(item=>{
          this.formImgList.push(this.imgUrl+item.url.replace('/root/file',''))
        })
        
        this.dialogFormVisible = true;
      },
      getList () {
          this.loading = true;
          const data = Object.assign({
            pageNum: this.page.currentPage,
            pageSize: this.page.pageSize,
          }, this.params)
          list(data).then(res => {
            this.loading = false;
            this.page.total = res.data.length;
            const data = res.data;
            this.data = data;
          })
        },
      },
  }, {
    name: '/carTrace/countData',
    res: (data) => {
      return {
        total: data.length,
        data: data
      }
    },
    pageNum: 'page',
    pageSize: 'size'
  })
  </script>
  
  <style lang="scss" scoped>
  </style>
