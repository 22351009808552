<template>
    <div class="h100">
      <el-card class="box-card">
        <!-- <div class="drawing-container">
          <div id="tui-image-editor"></div>
        </div> -->
      <el-row :gutter="20">
        <!-- <el-col :span="6">
          <el-input type="text" v-model="searchGlobalId"
          v-on:keyup.enter.native="searchAllListByGlobalId"
              placeholder="输入globalId搜索全部列"></el-input>
        </el-col>
        <el-col :span="3">
          <el-button @click="resetAllGlobal">重置全部列globalId</el-button>
        </el-col> -->
        <el-col :span="3">
        <el-button @click="resetAllSearchCondtion">重置全部列搜索条件</el-button>
      </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="3" v-for="(videoData,index) in videoDataList" :key="index">
          <!-- <el-row>
            <el-col :span="24">
              <el-input type="text" v-model="videoData.search.pictureId"
              :key="index" @input="inputchange" 
              v-on:keyup.enter.native="searchList(videoData.search,index,true)"
              placeholder="输入图片id搜索"></el-input>
            </el-col>
          </el-row> -->
          <el-row>
            <el-col :span="24">
              <el-input type="text" v-model="videoData.search.carId" 
              :key="index" @input="inputchange" 
              v-on:keyup.enter.native="searchList(videoData.search,index,true)"
              placeholder="输入车辆id搜索"></el-input>
            </el-col>
          </el-row>
          <!-- <el-row>
            <el-col :span="24">
              <el-input type="text" v-model="videoData.search.globalId" 
              :key="index" 
              @input="inputchange" 
              v-on:keyup.enter.native="searchList(videoData.search,index,true)"
              placeholder="输入globalId搜索"></el-input>
            </el-col>
          </el-row> -->
          <el-row>
            <el-col :span="12">
              <el-button size="mini" type="success" @click="searchList(videoData.search,index,true)">搜索</el-button>
              
            </el-col>
            <el-col :span="12">
              <el-button size="mini" @click="resetSearch(index)">重置</el-button>
            </el-col>
            <el-col :span="24">
              <el-button size="mini" style="margin-top:3px" @click="refreshCurrentPage(videoData.search,index,false)">刷新当前页</el-button>
            </el-col>
          </el-row>
          
          <el-row>
            <el-col :span="24" style="font-size:14px;">
                当前{{videoData&&videoData.page&&videoData.page.currentPage}}页｜共{{videoData&&videoData.page&&videoData.page.pages}}页
            </el-col>
          </el-row>
          <el-row style="font-size:14px;">
            <el-col :span="7">
                跳转到
            </el-col>
            <el-col :span="8">
                <input
                    class="page-input"
                    size="mini"
                    min="1"
                    type="number"
                    :max ="videoData.page.pages"
                    @keyup.enter="goPage(videoData.page.goToPageNumber,index)"
                    v-model="videoData.page.goToPageNumber" />
            </el-col>
            <el-col :span="6">页</el-col>
          </el-row>
          <el-row>
            <el-col :span="14">
              <el-button type="text" @click="goToPage(0,index)">上一页</el-button>
              <el-button type="text" @click="goToPage(1,index)">下一页</el-button>
            </el-col>
            <el-col :span="10">
              <el-tooltip v-if="videoData.searchDataInListPage" class="item" effect="dark" placement="top-start">
                <div slot="content">当前搜索条件在所有数据中的页数<br/>点击跳转到该页，但同时会清空搜索条件</div>
                <el-button  type="text" style="color:#67C23A" @click="clearSearchGotoPage(videoData.searchDataInListPage,index)">第{{videoData.searchDataInListPage}}页</el-button>
              </el-tooltip>
            </el-col>
          </el-row>
          <el-table
          :data="videoData.list"
            border
            style="width: 100%">
            <el-table-column
              prop="img"
              :label="'摄像头'+videoData.videoName">
              <template slot-scope="props">
                <!-- <div style="position:relative;top:0;left:0">
                  
                </div> -->
                <div style="position:relative;">
                  <!-- <el-checkbox class="car-trace" style="position:absolute;right:20px;bottom:14px;width:0px;height:20px" v-model="props.row.checked"></el-checkbox> -->
                  <!-- <el-image
                  style="width: 100%; height: 100px;"
                  :src="props.row.content?imgUrl+'carTraceBase/'+props.row.videoName+'/new/'+props.row.pictureId:imgUrl+'carTraceBase/'+props.row.videoName+'/'+props.row.pictureId"
                  fit="contain"
                  ></el-image> -->
                  <img  style="width:148px;height:100px" @click="viewDetail(props.row,index)"
                  :src="props.row.content && props.row.content!= ''?imgUrl+'carTraceBase/'+props.row.videoName+'/new/'+props.row.pictureId+'?time='+(new Date().toString()):imgUrl+'carTraceBase/'+props.row.videoName+'/'+props.row.pictureId+'?time='+(new Date().toString())"
                  />
                  <!-- :src="imgUrl+props.row.url.replace('/root/file','')" -->
                  <!-- @click="viewImg(imgUrl+props.row.url.replace('/root/file',''))" -->
                  
                  <div class="img-info" ><!--@click="props.row.checked = !props.row.checked"-->
                    <!-- <div style="font-weight: bold;">{{props.row.url.split('/')[props.row.url.split('/').length-1]}}</div>
                    
                    <div>视频时间：约{{secondsToHMS(Number(props.row.pictureId/30).toFixed(0))}}</div> -->
                    <div>{{props.row.pictureId}}</div>
                    <div>车辆Id：{{props.row.webCarIds}}</div>
                    <!-- <div>globalId：{{props.row.globalId}}</div> -->
                  </div>
                  <i class="el-icon-edit-outline" style="font-size:24px;color:#67C23A;position:right;right:-4px;bottom:-3px;position:absolute" @click="testOpen(props.row.id)"></i>
                </div>

              </template>
            </el-table-column>
          </el-table>
          <div style="text-align: center;color:grey"> {{'摄像头'+videoData.videoName}}</div>
         <div>
          <el-row>
            <el-col :span="14">
              <el-button type="text" @click="goToPage(0,index)">上一页</el-button>
              <el-button type="text" @click="goToPage(1,index)">下一页</el-button>
            </el-col>
            <el-col :span="10">
              <el-tooltip v-if="videoData.searchDataInListPage" class="item" effect="dark" placement="top-start">
                <div slot="content">当前搜索条件在所有数据中的页数<br/>点击跳转到该页，但同时会清空搜索条件</div>
                <el-button  type="text" style="color:#67C23A" @click="clearSearchGotoPage(videoData.searchDataInListPage,index)">第{{videoData.searchDataInListPage}}页</el-button>
              </el-tooltip>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" style="font-size:14px;">
                当前{{videoData&&videoData.page&&videoData.page.currentPage}}页｜共{{videoData&&videoData.page&&videoData.page.pages}}页
            </el-col>
          </el-row>
          <el-row style="font-size:14px;">
            <el-col :span="7">
                跳转到
            </el-col>
            <el-col :span="8">
                <input
                    class="page-input"
                    size="mini"
                    min="1"
                    type="number"
                    :max ="videoData.page.pages"
                    @keyup.enter="goPage(videoData.page.goToPageNumber,index)"
                    v-model="videoData.page.goToPageNumber" />
            </el-col>
            <el-col :span="6">页</el-col>
          </el-row>
          
          <!-- <el-row>
            <el-button type="primary" size="mini" @click="goPage(videoData.page.goToPageNumber,index)">确定</el-button>
          </el-row> -->
         </div>
        </el-col>
      </el-row>
      <el-dialog title="查看详情" append-to-body
      :visible.sync="detailVisible" @close="closeDetailWindow">
              <el-row>
                <el-col style="text-align: center;">
                  <el-image
                      v-if="currentObj"
                      style="width: 50%; height: 200px"
                      :src="detailImgList&&detailImgList.length>0?imgUrl+'carTraceBase/'+currentObj.videoName+'/new/'+currentObj.pictureId+'?time='+(new Date().toString()):imgUrl+'carTraceBase/'+currentObj.videoName+'/'+currentObj.pictureId"
                      fit="contain"
                      ></el-image>
                </el-col>
                <!-- {{imgUrl+currentObj.newUrl}}||{{imgUrl+currentObj.url}} -->
              </el-row>
              <template v-if="detailImgList.length">
                <el-row v-for="(item,index) in detailImgList">
                  <el-col :span="24" >
                    carId:{{webCarIdsArr[index]}},标记类型:{{item.classCode==1?'大卡车':'其他类汽车'}}
                  </el-col>
                </el-row>
              </template>
              <template v-else>
                <p>暂未标框</p>
              </template>
              
              <!-- <img :src="currentSrc" style="width:300px;height: 100%;" /> -->
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="testOpen(currentObj.id)">去标框</el-button>
                <el-button @click="closeDetailWindow">关闭</el-button>
              </div>
      </el-dialog>
      <el-dialog title="图片标框"  :fullscreen="true" append-to-body
      v-loading="editorLoading"
      :visible.sync="dialogFormVisible" @close="closeWindow">
        <el-row>
          <el-col :span="20">
            <p style="color:red">标框方法：1.每次标框请先选择工具栏矩形工具,从标框位置的左上角开始画框(否则会出现坐标错误),如果框标错了,请删除该框重新画，不要拖动修改位置。</p>
            <p style="color:red">*如果图片没加载出来，请关闭对话框重新打开。</p>
          </el-col>
          <el-col :span="4" style="text-align: right;">
            <el-button size="mini" type="primary" @click="saveNodeList">保存</el-button>
          </el-col>
          
        </el-row>
         
        <!-- <div class="drawing-container">
          <div id="tui-image-editor"></div>
        </div> -->
        
        </el-dialog>
        <el-dialog
          title="设置标记类型"
          :visible.sync="dialogClasscodeVisible"
          width="30%"
          :show-close="false"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          >
          <div>请设置类型:</div>
          <el-radio v-model="classCodeRadio" label="0">其它类汽车</el-radio>
          <el-radio v-model="classCodeRadio" label="1">大卡车</el-radio>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="checkElement">确 定</el-button>
          </span>
        </el-dialog>
        <!-- <tui-image-editor ref="tuiImageEditor" :include-ui="useDefaultUI" :options="options" @mousedown="imageEditorMouseDown"></tui-image-editor> -->
    <el-backtop target=".h100" :right="80" :bottom="50">
    </el-backtop>
  </el-card>
    </div>

  </template>
  
  <script>
  import { list,getDetailList,saveDrawList} from '@/api/imgRect/imgRect';
  import 'tui-color-picker/dist/tui-color-picker.css';
  import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from 'tui-image-editor';

const locale_zh = {
  ZoomIn: '放大',
  ZoomOut: '缩小',
  Hand: '手掌',
  History: '历史',
  Resize: '调整宽高',
  Crop: '裁剪',
  DeleteAll: '全部删除',
  Delete: '删除',
  Undo: '撤销',
  Redo: '反撤销',
  Reset: '重置',
  Flip: '镜像',
  Rotate: '旋转',
  Draw: '画',
  Shape: '形状标注',
  Icon: '图标标注',
  Text: '文字标注',
  Mask: '遮罩',
  Filter: '滤镜',
  Bold: '加粗',
  Italic: '斜体',
  Underline: '下划线',
  Left: '左对齐',
  Center: '居中',
  Right: '右对齐',
  Color: '颜色',
  'Text size': '字体大小',
  Custom: '自定义',
  Square: '正方形',
  Apply: '应用',
  Cancel: '取消',
  'Flip X': 'X 轴',
  'Flip Y': 'Y 轴',
  Range: '区间',
  Stroke: '描边',
  Fill: '填充',
  Circle: '圆',
  Triangle: '三角',
  Rectangle: '矩形',
  Free: '曲线',
  Straight: '直线',
  Arrow: '箭头',
  'Arrow-2': '箭头2',
  'Arrow-3': '箭头3',
  'Star-1': '星星1',
  'Star-2': '星星2',
  Polygon: '多边形',
  Location: '定位',
  Heart: '心形',
  Bubble: '气泡',
  'Custom icon': '自定义图标',
  'Load Mask Image': '加载蒙层图片',
  Grayscale: '灰度',
  Blur: '模糊',
  Sharpen: '锐化',
  Emboss: '浮雕',
  'Remove White': '除去白色',
  Distance: '距离',
  Brightness: '亮度',
  Noise: '噪音',
  'Color Filter': '彩色滤镜',
  Sepia: '棕色',
  Sepia2: '棕色2',
  Invert: '负片',
  Pixelate: '像素化',
  Threshold: '阈值',
  Tint: '色调',
  Multiply: '正片叠底',
  Blend: '混合色',
  Width: '宽度',
  Height: '高度',
  'Lock Aspect Ratio': '锁定宽高比例'
}
const customTheme = {
  "common.bi.image": "", // 左上角logo图片
  "common.bisize.width": "0px",
  "common.bisize.height": "0px",
  "common.backgroundImage": "none",
  "common.backgroundColor": "#f3f4f6",
  "common.border": "1px solid #333",

  // header
  "header.backgroundImage": "none",
  "header.backgroundColor": "#f3f4f6",
  "header.border": "0px",
  
  // load button
  "loadButton.backgroundColor": "#fff",
  "loadButton.border": "1px solid #ddd",
  "loadButton.color": "#222",
  "loadButton.fontFamily": "NotoSans, sans-serif",
  "loadButton.fontSize": "12px",
  "loadButton.display": "none", // 隐藏

  // download button
  "downloadButton.backgroundColor": "#fdba3b",
  "downloadButton.border": "1px solid #fdba3b",
  "downloadButton.color": "#fff",
  "downloadButton.fontFamily": "NotoSans, sans-serif",
  "downloadButton.fontSize": "12px",
  "downloadButton.display": "none", // 隐藏

  // icons default
  "menu.normalIcon.color": "#8a8a8a",
  "menu.activeIcon.color": "#555555",
  "menu.disabledIcon.color": "#ccc",
  "menu.hoverIcon.color": "#e9e9e9",
  "submenu.normalIcon.color": "#8a8a8a",
  "submenu.activeIcon.color": "#e9e9e9",

  "menu.iconSize.width": "24px",
  "menu.iconSize.height": "24px",
  "submenu.iconSize.width": "32px",
  "submenu.iconSize.height": "32px",

  // submenu primary color
  "submenu.backgroundColor": "#1e1e1e",
  "submenu.partition.color": "#858585",

  // submenu labels
  "submenu.normalLabel.color": "#858585",
  "submenu.normalLabel.fontWeight": "lighter",
  "submenu.activeLabel.color": "#fff",
  "submenu.activeLabel.fontWeight": "lighter",

  // checkbox style
  "checkbox.border": "1px solid #ccc",
  "checkbox.backgroundColor": "#fff",

  // rango style
  "range.pointer.color": "#fff",
  "range.bar.color": "#666",
  "range.subbar.color": "#d1d1d1",

  "range.disabledPointer.color": "#414141",
  "range.disabledBar.color": "#282828",
  "range.disabledSubbar.color": "#414141",

  "range.value.color": "#fff",
  "range.value.fontWeight": "lighter",
  "range.value.fontSize": "11px",
  "range.value.border": "1px solid #353535",
  "range.value.backgroundColor": "#151515",
  "range.title.color": "#fff",
  "range.title.fontWeight": "lighter",

  // colorpicker style
  "colorpicker.button.border": "1px solid #1e1e1e",
  "colorpicker.title.color": "#fff"
};
  export default {
    data() {
      return {
        webCarIdsArr:[],
        classCodeRadio:'0',
        dialogClasscodeVisible:false,
        editorLoading:false,
        optionsAdd:{},
        searchGlobalId:'',
        initObj:{
          videoId:'',
            videoName:'',
            list:[],
            search:{
                pictureId:'',
                carId:'',
                globalId:''
              },
            page:{
                currentPage:1,
                total:0,
                pages:0
            },
            searchDataInListPage:null
        },
        videoDataList:[],
        currentSrc:null,
        currentObj:null,
        detailVisible:false,
        dialogFormVisible:false,
        imgUrl:process.env.VUE_APP_BASE_API+'/',
        selectArr:'',
        hasGlobalnot0:false,
        useDefaultUI: true,
        options: { 
          includeUI: {
                loadImage: {
                  path: '',
                  name: "image",
                },
                menu: ["shape"], // 底部菜单按钮列表 隐藏镜像flip和遮罩mask
                initMenu: "shape", // 默认打开的菜单项
                menuBarPosition: "bottom", // 菜单所在的位置
                locale: locale_zh, // 本地化语言为中文
                theme: customTheme, // 自定义样式
              },
          cssMaxWidth: 700,
          cssMaxHeight: 1200,
          
        },
        instance: null,
        detailImgList:[],
        tempList:{},
        activeXpoint:0,
        activeYpoint:0,
        currentVideoIndex:null,
        objectAddedObj:null
      }
    },
    components: {
    'tui-image-editor': ImageEditor,
    },
    created(){
        for(let i=0;i<=7;i++){
            this.videoDataList.push(this.initObj)
        }
        for(let i=1;i<9;i++){
          this.getList(1,i)
        }
        
    },
    // mounted(){
    //   this.init()
    // },
    methods: {
      imageEditorMouseDown(event, originPointer){
        console.log(event)
        console.log(originPointer)
      },
      init() {
            this.instance = new ImageEditor(
              document.getElementById("tui-image-editor"),
              {
                includeUI: {
                  loadImage: {
                    path: 'https://img1.bdstatic.com/static/searchdetail/img/logo-2X_1a73254.png',
                    name: 'image'
                  },
                  menu:['shape'], // 底部菜单按钮列表 隐藏镜像flip和遮罩mask
                  initMenu: "shape", // 默认打开的菜单项
                  menuBarPosition: "bottom", // 菜单所在的位置
                  locale: locale_zh, // 本地化语言为中文
                  theme: customTheme, // 自定义样式
                  shape : {
                    stroke: '#ff0000',
                 }
                },
                cssMaxWidth: 1000, // canvas 最大宽度
                cssMaxHeight: 400, // canvas 最大高度
              }
            );
            document.getElementsByClassName("tui-image-editor-main")[0].style.top ="45px"; // 调整图片显示位置
            document.getElementsByClassName("tie-btn-reset tui-image-editor-item help") [0].style.display = "none";  // 隐藏顶部重置按钮
            document.getElementsByClassName("tui-image-editor-button circle")[0].style.display = "none";
            document.getElementsByClassName("tui-image-editor-button triangle")[0].style.display = "none";
            document.getElementsByClassName("tui-image-editor-partition")[0].style.display = "none";
            document.getElementsByClassName("tie-shape-color-button")[0].style.display = "none";
            document.getElementsByClassName("tui-image-editor-newline tui-image-editor-range-wrap")[0].style.display = "none";
            
            //document.getElementsByClassName("tie-shape-button")[0].classList.add("rect");
            
            //document.getElementsByClassName("tui-image-editor-controls") [0].style.display = "none";
            
            //console.log('222222')
            // this.instance.ui._submenuChangeTranssection=true
            // this.instance.startDrawingMode('SHAPE', {
            //     width: 10,
            //     color: 'rgba(255,0,0,0.5)'
            // });
            // this.instance.setBrush({
            //     width: 12,
            //     color: 'rgba(0, 0, 0, 0.5)'
            // });
            console.log('!this.detailImgList=====')
            console.log(this.detailImgList)
            // if(!this.detailImgList){
            //  setTimeout(()=>{
              
            //    this.instance.addShape('rect', {
            //           fill: 'transparent',
            //           stroke: 'red',
            //           strokeWidth: 3,
            //           width: 1,
            //           height: 1,
            //           left: 1,
            //           top: 1,
            //           isRegular:true
            //         }).then(objectProps => {
            //             console.log(objectProps.id);
            //             this.instance.removeObject(objectProps.id);
                        
            //         });
              
            //         this.editorLoading = false
            // },3000)
            
            // }else{
            //   //this.tempList = this.detailImgList
            //   setTimeout(()=>{
                
            //     for(let i =0;i<this.detailImgList.length;i++){
            //       //console.log('==i==='+i);
            //       ((i)=> {
            //         setTimeout(()=> {
                     
            //           this.instance.addShape('rect', {
            //           fill: 'transparent',
            //           stroke: 'red',
            //           strokeWidth: 3,
            //           width: this.detailImgList[i].width,
            //           height: this.detailImgList[i].height,
            //           left: this.detailImgList[i].left,
            //           top: this.detailImgList[i].top,
            //           isRegular:true
            //         }).then(objectProps => {
            //             console.log(objectProps.id);
            //             this.tempList[objectProps.id] = this.detailImgList[i];
            //             console.log("画完后====")
            //             console.log(this.tempList)
            //         });
            //         },1000)
            //       })(i)
                  
            //     }
                
            //   this.editorLoading = false
            // },3000)
            // }
          
      },
      secondsToHMS(seconds) {
        var hours = Math.floor(seconds / 3600);
        var minutes = Math.floor((seconds % 3600) / 60);
        var remainingSeconds = seconds % 60;
        
        return hours + ":" + minutes + ":" + remainingSeconds;
      },
      inputchange(){
        this.$forceUpdate();
      },
        getCheckedList(type){
            let checkedArr = []
            for(let i=0;i<this.videoDataList.length;i++){
                for(let j=0;j<this.videoDataList[i].list.length;j++){
                    if(this.videoDataList[i].list[j].checked){
                        if(this.selectArr.indexOf(i) == -1){
                          this.selectArr+=i
                        }
                        // 如果数据中 global!=0 ，弹提示消息 
                        if(this.videoDataList[i].list[j].globalId != 0){
                           this.hasGlobalnot0 = true;
                        }
                        checkedArr.push(this.videoDataList[i].list[j].id)
                    }
                }
            }
            if(Boolean(type) && this.selectArr.split('').length<=1){
              return false
            }
            return checkedArr.join(',');
        },
        viewDetail(row,videoIndex){
          console.log(row)
          this.currentVideoIndex = videoIndex
          this.currentSrc = this.imgUrl+row.url
          this.currentObj = row;
          this.webCarIdsArr=[];
          this.webCarIdsArr = row.webCarIds&&row.webCarIds.length>0?row.webCarIds.split(','):[];
          //detailImgList
          getDetailList(row.id).then(res=>{
            console.log(res.data.carDrawItemDtoList)
            console.log(res.data.content)
            if(res.data.content && res.data.content!=""){
              this.detailImgList = JSON.parse(res.data.content)
            }
            
            this.detailVisible = true
          })
          
        },
        testOpen(id){
          window.open('/mark/index.html?carNo='+id)
        },
      viewImg(){
        //this.currentSrc = src 
        //this.options.includeUI.loadImage.path = this.currentSrc
        
        this.dialogFormVisible = true 
        this.editorLoading = true     
        this.$nextTick(() => {
          // this.optionsAdd = this.options;
         // console.log(this.$refs.tuiImageEditor)
          // this.$refs.tuiImageEditor.invoke('loadImageFromURL', this.currentSrc, 'My sample image');
          
          //this.init()
              for(let i =0;i<this.detailImgList.length;i++){
                  //console.log('==i==='+i);
                  ((i)=> {
                    setTimeout(()=> {
                     
                      this.instance.addShape('rect', {
                      fill: 'transparent',
                      stroke: 'red',
                      strokeWidth: 3,
                      width: this.detailImgList[i].width,
                      height: this.detailImgList[i].height,
                      left: this.detailImgList[i].left,
                      top: this.detailImgList[i].top,
                      isRegular:true
                    }).then(objectProps => {
                        console.log(objectProps.id);
                        this.tempList[objectProps.id] = this.detailImgList[i];
                        console.log("画完后====")
                        console.log(this.tempList)
                    });
                    },1000)
                  })(i)
                  
                }
                
              this.editorLoading = false
          //在元素添加后弹出框选择（Classcode 中1代表大卡车，0代表其它类汽车，仅区分这两种）
          this.instance.on('objectAdded', (props)=> {
            console.log(props)
            console.log('objectAdded=====')
            //console.log(originPointer)
            //console.log(this.instance._graphics._objects)
            //this.tempList = this.instance._graphics._objects
            this.dialogClasscodeVisible = true;
            this.objectAddedObj = props;
            // var position = this.instance.getObjectPosition(this.objectAddedObj.id, 'left', 'top');
            // console.log(position)
            //this.checkElement(tempObj)
          })
          
          this.instance.on('mousedown', (event, originPointer)=> {
              console.log(event)
              console.log(originPointer)
              let op = JSON.parse(JSON.stringify(originPointer));
              console.log(op)
              let oop = Object.assign({},op)
              console.log('=======oop.x======')
              console.log(oop.x)
              console.log(oop.y)
              this.activeXpoint = oop&&oop.x?oop.x:0;
              this.activeYpoint = oop&&oop.y?oop.y:0;
          });


        })                                       
        
        //this.options.imgUrl = this.currentSrc
        //this.init()
        
        //this.$forceUpdate()
        //this.init()
      },
      checkElement(){
        //设定标记为小车或大货车：0-其他类汽车，1-大卡车
        if(!this.tempList[this.objectAddedObj.id] && this.objectAddedObj){
          this.tempList[this.objectAddedObj.id] = Object.assign(this.objectAddedObj, {classCode:Number(this.classCodeRadio),x:this.activeXpoint,y:this.activeYpoint})
          console.log(this.tempList)
        }
        this.dialogClasscodeVisible = false
      
      },
      saveNodeList(){
        let allNodeList = JSON.parse(JSON.stringify(this.instance._graphics._objects));
        console.log(allNodeList)
        let finalNodeList = []
        for  (let key in allNodeList) {
          console.log(allNodeList[key]) 
          console.log(this.tempList[key].classCode)
          finalNodeList.push({
                x: this.tempList[key].x,
                y: this.tempList[key].y,
                left:this.tempList[key].left,
                top:this.tempList[key].top,
                width: allNodeList[key].width,
                height: allNodeList[key].height,
                classCode: this.tempList[key].classCode
              })
        }
        // allNodeList.forEach((value,key) => {
        //     console.log(value)
        //     console.log(key)
        //     if(this.tempList[key]){
        //       // 存在
        //       finalNodeList.push({
        //         x: value.left,
        //         y: value.top,
        //         width: value.width,
        //         height: value.height,
        //         classCode: this.tempList[key].classcode
        //       })
        //     }
        // });

        //保存节点接口
        //finalNodeList
        this.currentObj.carDrawItemDtoList = finalNodeList
        saveDrawList(this.currentObj).then(res=>{
          console.log('保存成功')
          this.tempList = {}
           
          //detailImgList
          getDetailList(this.currentObj.id).then(res=>{
            console.log(res.data.content)
            this.currentObj = res.data;
            this.detailImgList = JSON.parse(res.data.content)
            this.dialogFormVisible= false;
            this.webCarIdsArr=[];
            this.webCarIdsArr = this.currentObj.webCarIds&&this.currentObj.webCarIds.length>0?this.currentObj.webCarIds.split(','):[this.currentObj.webCarIds];
            this.$forceUpdate()
          })
          
        })
        //console.log(allNodeList.size)
      },
      closeDetailWindow(){
        //重新搜索数据todo
        let parames = {
              pageNum:this.videoDataList[this.currentVideoIndex].page.currentPage,
              pageSize:5,
              search:this.videoDataList[this.currentVideoIndex].search,
              videoId:this.videoDataList[this.currentVideoIndex].videoId
            }
            this.refreshDataList(parames,this.currentVideoIndex)
        this.detailVisible = false;
      },
      closeWindow(){
        this.dialogFormVisible = false;
      },
      goPage(page,index){
        if(page<1 || page>this.videoDataList[index].page.pages){
            this.$message.error('页数在1到'+this.videoDataList[index].page.pages+'之间');
            return;
        }

        //this.videoDataList[index].page.currentPage = page;
        let parames = {
            pageNum:page,
            search:this.videoDataList[index].search,
            pageSize:5,
            videoId:this.videoDataList[index].videoId
        }
        this.refreshDataList(parames,index)
      },
      resetSearch(index){
        this.videoDataList[index].search.pictureId = '';
        this.videoDataList[index].search.carId = '';
        this.videoDataList[index].search.globalId = '';
        this.videoDataList[index].searchDataInListPage = 0;
        this.searchList(this.videoDataList[index].search,index,false);

      },
      resetAllSearchCondtion(){
        for(let i =0;i<=8;i++){
          this.searchGlobalId = '';
          this.videoDataList[i].search.globalId = this.searchGlobalId;
          this.videoDataList[i].search.pictureId = '';
          this.videoDataList[i].search.carId = '';
          this.videoDataList[i].searchDataInListPage = 0;
          this.searchList(this.videoDataList[i].search,i,false);
        }
      },
      resetAllGlobal(){
        for(let i =0;i<=8;i++){
          this.searchGlobalId = '';
          this.videoDataList[i].search.globalId = this.searchGlobalId;
          this.searchList(this.videoDataList[i].search,i,true);
        }
      },
      searchAllListByGlobalId(){
        for(let i =0;i<8;i++){
          this.videoDataList[i].search.globalId = this.searchGlobalId;
          this.searchList(this.videoDataList[i].search,i,true);
        }

      },
      refreshCurrentPage(search,index,setSearchDataInListPage){
        let parames = {
            pageNum:this.videoDataList[index].page.currentPage,
            pageSize:5,
            search,
            videoId:this.videoDataList[index].videoId
        }
        this.refreshDataList(parames,index,setSearchDataInListPage)
        
      },
      searchList(search,index,setSearchDataInListPage){
        let parames = {
            pageNum:1,
            pageSize:5,
            search,
            videoId:this.videoDataList[index].videoId
        }
        this.refreshDataList(parames,index,setSearchDataInListPage)
        
      },
      refreshDataList(parames,index,setSearchDataInListPage){
        list(parames).then(res => {
            let result = res.data;
            for(let i = 0;i<result.records.length;i++){
                result.records[i].checked = false
            }
            this.videoDataList[index].list = result.records;
            this.videoDataList[index].page.currentPage = parames.pageNum;
            this.videoDataList[index].page.total = result.total;
            this.videoDataList[index].page.pages = result.pages;
            this.videoDataList[index].page.goToPageNumber = null;
            if(setSearchDataInListPage){
              this.videoDataList[index].searchDataInListPage = result.countId?Number(result.countId):0;
            }
            this.$forceUpdate()
        })
      },
      goToPage(plus,index){
        // 0:上一页 1:下一页
        let tempPage = 1
        if(plus){
          //下一页
            this.videoDataList[index].page.currentPage=Number(this.videoDataList[index].page.currentPage)+1;
            tempPage = (this.videoDataList[index].page.currentPage <= this.videoDataList[index].page.pages)?this.videoDataList[index].page.currentPage:this.videoDataList[index].page.pages;
        }else{
          //上一页
          this.videoDataList[index].page.currentPage=Number(this.videoDataList[index].page.currentPage)-1;
            tempPage = (this.videoDataList[index].page.currentPage >1 )?this.videoDataList[index].page.currentPage:1;
        }
        
    
        //this.videoDataList[index].page.currentPage = tempPage;
        let parames = {
            pageNum:tempPage,
            pageSize:5,
            search:this.videoDataList[index].search,
            videoId:this.videoDataList[index].videoId
        }
        this.refreshDataList(parames,index)
         
      }, 
      reloadList(){
        let arr = this.selectArr.split('');
        this.selectArr = '';
       
        for(let i = 0;i< arr.length;i++){
            let parames = {
              pageNum:this.videoDataList[arr[i]].page.currentPage,
              pageSize:5,
              search:this.videoDataList[arr[i]].search,
              videoId:this.videoDataList[arr[i]].videoId
            }
            this.refreshDataList(parames,arr[i])
        }
      },
      clearSearchGotoPage(currentPage,index){
        this.videoDataList[index].search.pictureId = '';
        this.videoDataList[index].search.carId = '';
        this.videoDataList[index].search.globalId = '';
        this.videoDataList[index].searchDataInListPage = null;
        let parames = {
            pageNum:currentPage,
            pageSize:5,
            search:this.videoDataList[index].search,
            videoId:this.videoDataList[index].videoId
        }
        this.refreshDataList(parames,index)
      },
      getList (currentPage,videoId) {
        let parames = {
            pageNum:currentPage,
            search:this.videoDataList[videoId-1].search,
            pageSize:5,
            videoId:videoId
        }
        list(parames).then(res => {

          let result = res.data;
          if(result.records.length>0){
            let videoId = result.records[0].videoId;
            let obj ={
              videoId:result.records[0].videoId,
              videoName:result.records[0].videoName,
              list:result.records,
              search:{
                pictureId:'',
                carId:'',
                globalId:''
              },
              page:{
                  currentPage:currentPage,
                  total:result.total,
                  pages:result.pages,
                  goToPageNumber:null
              },
              searchDataInListPage:null
            }
          for(let i = 0;i<obj.list.length;i++){
            obj.list[i].checked = false
          }
          
          this.videoDataList[videoId-1] = obj;
          this.$forceUpdate()
          }
          
        })
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>

.el-row{
    margin-bottom: 5px;
  }
  .fixed {

  position: fixed;

  top: 0;

  left: 0;

  right: 0;

  z-index: 99;

  }
  .page-input{
      height:20px !important;
      line-height:20px !important;
      padding:0 5px !important;
      border:1px solid #DCDFE6;
      border-radius: 5px;
    }
    .img-info{
      z-index:999;font-size:12px;color: #f00;
    }
    
//  .el-checkbox__input .el-checkbox__inner {
//       height:28px !important;
//       width:28px !important;
//     }

    .car-trace {

  ::v-deep .el-checkbox__inner {
   
    width:28px;
    height:28px;
  }
  ::v-deep .el-checkbox__input .el-checkbox__inner::after {
    // 对号
    border: 2px solid #fff;
    // 不覆盖下面的 会 导致对号变形
    box-sizing: content-box;
    content: "";
    border-left: 0;
    border-top: 0;
    height: 17px;
    left: 8px;
    position: absolute;
    top: 1px;
    width: 8px;
    //
    transform: rotate(45deg) scaleY(1);
  }
}
.drawing-container {
  height: 600px;
  position: relative;
  .save {
    position: absolute;
    right: 50px;
    top: 15px;
  }
}
  </style>
  
  